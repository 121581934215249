//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}
.card.bg-dark {
  background-color: $black !important;
}

.card-body {
  padding: 5px; // あるいは任意の値
}
