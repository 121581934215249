.username {
  font-size: 80%;
}

.sales-goods-name {
  font: 80%;
  width: auto;
}
.sales-price,
.sales-count {
  font: 80%;
  text-align: center;
  min-width: 60px;
}
.product-name {
  max-width: 500px;
}

.sales-amount,
.stocks-count {
  font: 80%;
  text-align: right;
  min-width: 60px;
}

.sales-pay-type {
  font: 80%;
  text-align: left;
  min-width: 80px;
}

.sales-type {
  font: 80%;
  min-width: 60px;
}

i.ti-user {
  font-size: 28px;
}

.user-code {
  max-width: 80px;
}

.shelves-date,
.shelf-column {
  min-width: 70px;
}
