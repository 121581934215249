.quantity-inputs {
  display: flex;
  justify-content: center; /* 中央寄せ */
  align-items: center;
  gap: 20px; /* ボタンと入力フィールドの間のスペースを広げる */
}

.quantity-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantity-number {
  width: 60px; /* サイズを大きくする */
  height: 40px; /* 高さも調整 */
  font-size: 20px; /* フォントサイズを大きくする */
  text-align: center;
  margin: 10px 0; /* 上下の余白を広げる */
}

.arrow {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 30px; /* アイコンのサイズを大きくする */
}

.unit-label {
  margin-left: 15px;
  font-size: 18px; /* フォントサイズを大きくする */
}
