//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-medium;
  }

  tr:nth-child(odd) {
    --bs-table-color: #000033; // テキストの色を深いブルーに設定
    --bs-table-bg: #edf7ff; // 背景色をパステルブルーに設定
  }

  tr:nth-child(even) {
    --bs-table-color: #000033; // テキストの色を深いブルーに設定
    --bs-table-bg: #f7fcff; // 背景色をもう少し明るいパステルブルーに設定
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.product-name-column {
  min-width: 100px; // 最低幅を設定
  overflow: hidden;
}

.row-critical123 {
  background-color: yellow !important; /* 強制的に背景色を上書き */
  font-weight: bold;
  color: red;
}
